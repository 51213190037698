
import * as React from 'react';
import * as PropTypes from 'prop-types'

import moment from 'moment';
import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as auth from '../../../utils/auth';

import Loading from '../../global/loading';
import { clickHandler, isNullOrEmpty, mapLocalDateTime, parseLocalDateTime } from '../../../utils/util';
import CurrencyAmount from '../../global/currencyAmount';
import ApiError from '../../global/apiError';
import SendVoucherForm from '../pointOfSale/sendVoucherForm';
import { AuditTrailEntry } from '../../../store/pages/diary/types';
import AuditLogEntry from '../../global/auditLogEntry';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface GetVoucherDetailsResponse {
    voucher: VoucherDetail;
    auditLog: AuditTrailEntry[];
}

interface Redemption {
    redemptionDateTime: Date;
    billId: string;
    billNumber: string;
    redemptionAmount: number;
    venueName: string;
    void: boolean;
}

interface VoucherDetail {
    voucherId: string;
    voucherCode: string;
    purchaseDate: Date;
    purchaseVenueId: string;
    expiryDate: Date | null;
    hasExpired: boolean;
    isActive: boolean;
    redemptionAmount: number;
    remainingBalance: number;
    activeFromDate: Date | null;
    billId: string;
    billNumber: string;
    customerEmail: string;
    customerFirstname: string;
    customerLastname: string;
    revoked: boolean;
    revocationReason: string | null;
    revocationDateTime: Date | null;
    revokedBy: string | null;
    revokedById: string | null;
    redemptions: Redemption[]
}

interface VoucherDetailsProps {
    voucherId: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: () => void;
    logout: () => void;
}

interface VoucherDetailsState {
    loading: boolean;
    loadError: api.ApiError | null;
    updating: boolean;
    updateError: api.ApiError | null;
    updateErrorKey: string | null;
    expiryDate: ct.FormValue<moment.Moment | null>;
    voucherDetails: VoucherDetail | null;
    auditLog: AuditTrailEntry[];
    confirmRevocation: boolean;
    revocationReason: ct.FormValue<string>;
    revoking: boolean;
    revocationErrorMessgae: string | null;
    revocationError: api.ApiError | null;
    sendVoucher: boolean;
}

class VoucherDetails extends React.Component<VoucherDetailsProps, VoucherDetailsState> {

    constructor(props: VoucherDetailsProps) {
        super(props);

        this.state = {
            loading: true,
            loadError: null,
            updating: false,
            updateError: null,
            updateErrorKey: null,
            expiryDate: this.validateExpiryDate(null),
            voucherDetails: null,
            auditLog: [],
            confirmRevocation: false,
            revocationReason: this.validateRevocationReason(''),
            revoking: false,
            revocationErrorMessgae: null,
            revocationError: null,
            sendVoucher: false
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    loadVoucherDetails = (voucherId: string) => {
        api.getWithAuth<GetVoucherDetailsResponse>(`api/v1/voucher/${voucherId}/details`, this.props.logout)
            .subscribe(
                res => this.setState({
                    loading: false,
                    voucherDetails: {
                        ...res.voucher,
                        purchaseDate: parseLocalDateTime(res.voucher.purchaseDate),
                        activeFromDate: mapLocalDateTime(res.voucher.activeFromDate),
                        expiryDate: mapLocalDateTime(res.voucher.expiryDate),
                        revocationDateTime: mapLocalDateTime(res.voucher.revocationDateTime),
                        redemptions: res.voucher.redemptions.map(r => ({ ...r, redemptionDateTime: parseLocalDateTime(r.redemptionDateTime) }))
                    },
                    auditLog: res.auditLog.map(e => ({ ...e, time: parseLocalDateTime(e.time) })),
                    expiryDate: this.validateExpiryDate(res.voucher.expiryDate ? moment(res.voucher.expiryDate) : null),
                    loadError: null
                }),
                err => this.setState({ loading: false, voucherDetails: null, loadError: err }))
    }

    componentDidMount() {
        const { voucherId } = this.props;
        this.loadVoucherDetails(voucherId);
    }

    validateRevocationReason = (val: string) => v.validate(val, 'revocationReason', [v.required], []);

    close = () => this.props.close();

    send = () => this.setState({ sendVoucher: true });

    revoke = () => this.setState({ confirmRevocation: true, revocationReason: this.validateRevocationReason('') });

    confirmRevocation = () => {
        const { t } = this.context;
        const { voucherDetails, revocationReason } = this.state;

        if (!voucherDetails) return;

        if (!revocationReason.isValid) {
            this.setState({ revocationErrorMessgae: t('Global:formNotValid') })
            return;
        }

        this.setState({ revoking: true, revocationErrorMessgae: null, revocationError: null }, () => api.postWithAuth(`api/v1/voucher/${voucherDetails.voucherId}/revoke`, { revocationReason: revocationReason.value }, this.props.logout)
            .subscribe(
                res => this.setState({ revoking: false, revocationErrorMessgae: null, revocationError: null, confirmRevocation: false, revocationReason: this.validateRevocationReason('') }, () => this.loadVoucherDetails(voucherDetails.voucherId)),
                err => this.setState({ revoking: false, revocationErrorMessgae: null, revocationError: err })))
    }

    cancelRevocation = () => {
        this.setState({ confirmRevocation: false, revocationReason: this.validateRevocationReason('') });
    }

    validateExpiryDate = (val: moment.Moment | null) => v.validate(val, 'expiryDate', [], []);

    expiryDateChanged = (date: moment.Moment) => this.setState({ expiryDate: this.validateExpiryDate(date) });

    expiryChanged = (original: Date | null, newDate: moment.Moment | null) => {
        if (!original) return newDate !== null;
        if (!newDate) return original !== null;
        return original.getFullYear() !== newDate.years() || original.getMonth() !== newDate.months() || original.getDate() !== newDate.date();
    }

    updateVoucherExpiry = () => {
        const { voucherDetails, expiryDate } = this.state;

        if (!voucherDetails)
            return;

        const expiryDateValue = expiryDate.value;

        const voucherId = voucherDetails.voucherId;
        if (!expiryDateValue) {
            this.setState({ updating: false, updateError: null, updateErrorKey: 'VoucherDetails:MissingExpiryDate' });
            return;
        }

        this.setState({ updating: true, updateError: null, updateErrorKey: null }, () => api.putWithAuth(`api/v1/voucher/${voucherId}/expiry`, { expiryDate: expiryDateValue.toDate().toYMDDateString() }, this.props.logout)
            .subscribe(
                res => this.setState({ updating: false, updateError: null }, () => this.loadVoucherDetails(voucherId)),
                err => this.setState({ updating: false, updateError: err }))
        );
    }

    render() {
        const { loading, loadError, voucherDetails, auditLog, expiryDate, confirmRevocation, revocationReason, revocationError, revocationErrorMessgae,
            revoking, sendVoucher, updating, updateError, updateErrorKey } = this.state;
        const { timeFormat, dateFormat, logout } = this.props;
        const { t } = this.context;

        const isClientAdmin = auth.isClientAdmin();

        if (loading) {
            return <div><Loading /></div>
        }

        if (!voucherDetails) {
            return <div className='flex-stretch'>
                <div className='alert alert-danger'>{t('VoucherDetails:voucherNotFound')}</div>
                <div className='btn-toolbar'>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:close')}</button>
                </div>
            </div>
        }

        if (loadError) {
            return <div className='flex-stretch'>
                <ApiError error={loadError} />
                <div className='btn-toolbar'>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:close')}</button>
                </div>
            </div>
        }

        return <div className='flex-stretch'>
            <div>
                <h1 style={{ display: 'inline-block', marginRight: '20px' }}>Voucher {voucherDetails.voucherCode} details</h1>
                {this.renderVoucherStatus(voucherDetails)}
            </div>

            <div className='row row-m-t'>
                <div className='col-xs-12 col-md-4'>
                    <label>{t('VoucherDetails:purchaseDate')}</label>
                    <div>{voucherDetails.purchaseDate.toAbbrDateTimeString(timeFormat, dateFormat, t)}</div>
                </div>
                <div className='col-xs-12 col-md-4'>
                    <label>{t('VoucherDetails:activeFromDate')}</label>
                    <div>{voucherDetails.activeFromDate ? voucherDetails.activeFromDate.toAbbrDateString(dateFormat, t) : null}</div>
                </div>
                <div className='col-xs-12 col-md-4'>
                    <div className='flex flex-start'>
                        <div className='flex-stretch'><ct.DatePicker id='expiryDate' labelKey='VoucherDetails:expiryDate' value={expiryDate} callback={this.expiryDateChanged} disabled={updating} dateFormat={dateFormat} timeFormat={undefined} /></div>
                        <button className='btn btn-info flex-shrink' style={{ marginLeft: '10px', marginTop: '20px' }} onClick={e => clickHandler(e, this.updateVoucherExpiry)} disabled={updating || !this.expiryChanged(voucherDetails.expiryDate, expiryDate.value)}>{t('Global:update')}</button>
                    </div>
                </div>
            </div>
            <div className='row row-m-t'>
                <div className='col-xs-12 col-md-4'>
                    <label>{t('VoucherDetails:redemptionAmount')}</label>
                    <div><CurrencyAmount amount={voucherDetails.redemptionAmount} /></div>
                </div>
                <div className='col-xs-12 col-md-4'>
                    <label>{t('VoucherDetails:remainingBalance')}</label>
                    <div><CurrencyAmount amount={voucherDetails.remainingBalance} /></div>
                </div>
            </div>
            <div className='row row-m-t'>
                <div className='col-xs-12 col-md-4'>
                    <label>{t('VoucherDetails:purchasedBy')}</label>
                    <div>{voucherDetails.customerEmail} {voucherDetails.customerFirstname} {voucherDetails.customerLastname}</div>
                </div>
                <div className='col-xs-12 col-md-4'>
                    <label>{t('VoucherDetails:billNumber')}</label>
                    <div>{voucherDetails.billNumber}</div>
                </div>
            </div>
            {voucherDetails.revoked ?
                <div className='row row-m-t'>
                    <div className='col-xs-12 col-md-4'>
                        <label>{t('VoucherDetails:revoked')}</label>
                        <div>{voucherDetails.revocationDateTime ? voucherDetails.revocationDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t) : null}</div>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                        <label>{t('VoucherDetails:revokedBy')}</label>
                        <div>{voucherDetails.revokedBy}</div>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                        <label>{t('VoucherDetails:revocationReason')}</label>
                        <div>{voucherDetails.revocationReason}</div>
                    </div>
                </div>
                : null}
            <p />

            {voucherDetails.redemptions.length > 0
                ?
                <div className='row row-m-t'>
                    <div className='col-xs-12'>
                        <label>{t('VoucherDetails:redemptions')}</label>
                        <table className='table table-condensed'>
                            <thead>
                                <tr>
                                    <th>{t('VoucherDetails:dateHeader')}</th>
                                    <th>{t('VoucherDetails:amountHeading')}</th>
                                    <th>{t('VoucherDetails:billHeading')}</th>
                                    <th>{t('VoucherDetails:venueHeading')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {voucherDetails.redemptions.sort((r1, r2) => r1.redemptionDateTime.getTime() - r2.redemptionDateTime.getTime()).map(r => <tr>
                                    <td>{r.redemptionDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t)}</td>
                                    <td><CurrencyAmount amount={r.redemptionAmount} /></td>
                                    <td>{r.billNumber}</td>
                                    <td>{r.venueName}</td>
                                    <td>{r.void ? <span className='label label-danger'>{t('Global:void')}</span> : null}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                : null}

            <section className='panel panel-default'>
                <div className='panel-heading'>
                    <h4 className='no-margin'>{t('Global:auditLog')}</h4>
                </div>
                <div className='panel-body'>
                    <ul className='list-unstyled'>
                        {auditLog.sort((l1, l2) => l2.time.getTime() - l1.time.getTime()).map((l, ix) => <AuditLogEntry key={ix} logEntry={l} timeFormat={timeFormat} dateFormat={dateFormat} />)}
                    </ul>
                </div>
            </section>

            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)} disabled={revoking}>{t('Global:close')}</button>
                {!isClientAdmin || voucherDetails.revoked || voucherDetails.hasExpired ? null : <button className='btn btn-danger' onClick={e => clickHandler(e, this.revoke)} disabled={revoking}>{t('VoucherDetails:revoke')}</button>}
                <button className='btn btn-info' onClick={e => clickHandler(e, this.send)} disabled={revoking}>{t('Global:send')}</button>

            </div>

            {!isNullOrEmpty(updateErrorKey) ? <div className='alert alert-danger'>{t(updateErrorKey)}</div> : null}
            {updateError ? <ApiError error={updateError} /> : null}

            {confirmRevocation ? <div className='overlay'>
                <div className='overlay-content'>
                    <div>
                        <div className='row row-m-t'>
                            <div className='col-xs-12'>
                                <h4>{t('VoucherDetails:confirmRevocation')}</h4>
                            </div>
                        </div>
                        <div className='row row-m-t'>
                            <div className='col-xs-12'>
                                <ct.TextBox id='revocationReason' labelKey='VoucherDetails:revocationReason' value={revocationReason} callback={val => this.setState({ revocationReason: this.validateRevocationReason(val) })} />
                            </div>
                        </div>
                        <div className='btn-toolbar'>
                            <button className='btn btn-danger' onClick={e => clickHandler(e, this.confirmRevocation)}>{t('VoucherDetails:revoke')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, this.cancelRevocation)}>{t('Global:cancel')}</button>
                        </div>
                        {!isNullOrEmpty(revocationErrorMessgae) ? <div className='alert alert-danger'>{revocationErrorMessgae}</div> : null}
                        {revocationError ? <ApiError error={revocationError} /> : null}
                    </div>
                </div>
            </div> : null}

            {sendVoucher
                ? <div className='overlay'>
                    <div className='overlay-content'>
                        <SendVoucherForm
                            venueId={voucherDetails.purchaseVenueId}
                            voucher={{ voucherId: voucherDetails.voucherId, voucherCode: voucherDetails.voucherCode, voucherAmount: voucherDetails.redemptionAmount, revoked: voucherDetails.revoked }}
                            customerId={null}
                            customerEmail={null}
                            close={() => this.setState({ sendVoucher: false })}
                            logout={logout} />
                    </div>
                </div>
                : null}

        </div>
    }

    renderVoucherStatus = (voucherDetails: VoucherDetail) => {
        const { t } = this.context;

        const wrap = (content: JSX.Element) => <h3 style={{ display: 'inline-block', marginRight: '20px' }}>{content}</h3>

        if (voucherDetails.revoked) {
            return wrap(<label className='label label-danger'>{t('Global:revoked')}</label>)
        } else if (voucherDetails.hasExpired) {
            return wrap(<label className='label label-danger'>{t('Global:expired')}</label>)
        } else if (voucherDetails.isActive) {
            return wrap(<label className='label label-success'>{t('Global:active')}</label>)
        }

        return null;
    }
}

export default VoucherDetails;