import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from './global/app';
import Auth from './global/auth';
import Header from './global/header';
import Page from './global/page';

import Home from './pages/home/homePage';
import LoginPage from './pages/login/loginPage';
import ForgottenPasswordPage from './pages/login/forgottenPasswordPage';
import ResetPasswordPage from './pages/login/resetPasswordPage';
import Users from './pages/users/usersPage';
import Clients from './pages/clients/clientsPage';
import Venues from './pages/venues/venuesPage';
import VenueDetail from './pages/venues/venueDetailPage';
import ActivityFormatPage from './pages/venues/activityFormat/activityFormatPage';
import ProductPage from './pages/venues/products/productPage';
import Diary from './pages/diary/diaryPage';
import DailySummary from './pages/dailySummary/dailySummaryPage';
import Event from './pages/diary/eventPage';
import RegistrationKiosk from './pages/registrationKiosk/registrationPage';
import Reception from './pages/reception/receptionPage';
import TaxRates from './pages/taxRates/taxRatesPage';
import Tags from './pages/tags/tagsPage';
import Integrations from './pages/integrations/integrationsPage';
import Affiliates from './pages/affiliates/affiliatesPage';
import Emails from './pages/emails/emailsPage';
import PointOfSalePage from './pages/pointOfSale/pointOfSalePage';
import CampaignsPage from './pages/campaigns/campaignsPage';
import EditCampaignPage from './pages/campaigns/editCampaignPage';
import CampaignDetailsPage from './pages/campaigns/campaignDetailsPage';
import CustomersPage from './pages/customers/customersPage';
import CustomersDetailsPage from './pages/customers/customerDetailsPage';
import DownloadsPage from './pages/downloads/downloadsPage';
import ReportsPage from './pages/reports/reportsPage';
import ReportDefinitionForm from './pages/reports/reportDefinitionForm';
import ViewReportPage from './pages/reports/viewReportPage';
import AnalyticsPage from './pages/analytics/analyticsPage';
import VouchersPage from './pages/vouchers/vouchersPage';
import EditVouchersPage from './pages/vouchers/editVouchersPage';
import VoucherProductDetailsPage from './pages/vouchers/voucherProductDetailsPage';
import MembershipsPage from './pages/memberships/membershipsPage';
import MembershipTypeDetails from './pages/memberships/membershipTypeDetails';
import MembershipDetails from './pages/memberships/membershipDetails';

import AdminToolsPage from './pages/adminTools/adminToolsPage';
import MigrateClientImages from './pages/adminTools/migrateClientImages';

export default () =>
    <div className='full-height'>
        <Switch>
            <Route exact path='/registration' component={RegistrationKiosk} />
            <Route exact path='/downloads' component={DownloadsPage} />
            <div className='routes-wrapper'>
                <Header />
                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/login/forgottenPassword' component={ForgottenPasswordPage} />
                <Route exact path='/login/resetPassword' component={ResetPasswordPage} />
                <Auth>
                    <App>
                        <Route exact path='/' render={props => (<Page {...props} component={Home} title='Home' />)} />
                        <Route exact path='/diary' render={props => (<Page {...props} component={Diary} title='Diary' />) } />
                        <Route exact path='/diary/:venueId/event/:eventId' render={props => (<Page {...props} component={Event} title='Diary Booking' />)} />
                        <Route exact path='/dailySummary' render={props => (<Page {...props} component={DailySummary} title='Daily Summary' />)} />
                        <Route exact path='/reception' render={props => (<Page {...props} component={Reception} title='Reception' />)} />
                        <Route exact path='/pointofsale' render={props => (<Page {...props} component={PointOfSalePage} title='Point of Sale'/>)} />
                        <Route exact path='/campaigns' render={props => (<Page {...props} component={CampaignsPage} title='Campaigns' />)} />
                        <Route exact path='/campaign/new' render={props => (<Page {...props} component={EditCampaignPage} title='Add Campaign'/>)} />
                        <Route exact path='/campaign/:campaignId/edit' render={props => (<Page {...props} component={EditCampaignPage} title='Edit Campaign' />)} />
                        <Route exact path='/campaign/:campaignId/view' render={props => (<Page {...props} component={CampaignDetailsPage} title='Campaign Details' />)} />
                        <Route exact path='/customers' render={props => (<Page {...props} component={CustomersPage} title='Customers' />)} />
                        <Route exact path='/customer/:customerId' render={props => (<Page {...props} component={CustomersDetailsPage} title='Customer Details' />)} />
                        <Route exact path='/users' render={props => (<Page {...props} component={Users} title='Users'/> )} />
                        <Route exact path='/clients' render={props => (<Page {...props} component={Clients} title='Clients' />)} />
                        <Route exact path='/analytics' render={props => (<Page {...props} component={AnalyticsPage} title='Analytics' />)} />
                        <Route exact path='/venues' render={props => (<Page {...props} component={Venues} title='Venues' />)} />
                        <Route exact path='/venue/:venueId' render={props => (<Page {...props} component={VenueDetail} title='Venue Settings' />)} />
                        <Route exact path='/venue/:venueId/af/:activityFormatId' render={props => (<Page {...props} component={ActivityFormatPage} title='Edit Activity Format' />)} />
                        <Route exact path='/venue/:venueId/product/:productId' render={props => (<Page {...props} component={ProductPage} title='Edit Product' />)} />
                        <Route exact path='/taxRates' render={props => (<Page {...props} component={TaxRates} title='Tax Rates' />)} />
                        <Route exact path='/tags' render={props => (<Page {...props} component={Tags} title='Tags' />)} />
                        <Route exact path='/vouchers' render={props => (<Page {...props} component={VouchersPage} title='Vouchers' />)} />
                        <Route exact path='/vouchers/edit' render={props => (<Page {...props} component={EditVouchersPage} title='Edit Vouchers' />)} />
                        <Route exact path='/vouchers/:voucherProductId/view' render={props => (<Page {...props} component={VoucherProductDetailsPage} title='Voucher Details' />)} />
                        <Route exact path='/memberships' render={props => (<Page {...props} component={MembershipsPage} title='Memberships' />)} />
                        <Route exact path='/memberships/:membershipTypeId/view' render={props => (<Page {...props} component={MembershipTypeDetails} title='Membership Type Details' />)} />
                        <Route exact path='/membership/:membershipId' render={props => (<Page {...props} component={MembershipDetails} title='Membership details' />)} />
                        <Route exact path='/integrations' render={props => (<Page {...props} component={Integrations} title='Integrations' />)} />
                        <Route exact path='/affiliates' render={props => (<Page {...props} component={Affiliates} title='Affiliates' />)} />
                        <Route exact path='/emails' render={props => (<Page {...props} component={Emails} title='Emails' />)} />
                        <Route exact path='/reports' render={props => (<Page {...props} component={ReportsPage} title='Reports' />)} />
                        <Route exact path='/reports/add' render={props => (<Page {...props} component={ReportDefinitionForm} title='Add Report' />)} />
                        <Route exact path='/reports/:reportDefinitionId/edit' render={props => (<Page {...props} component={ReportDefinitionForm} title='Edit Report' />)} />
                        <Route exact path='/reports/:reportDefinitionId/view' render={props => (<Page {...props} component={ViewReportPage} title='View Report' />)} />
                        <Route exact path='/admintools' render={props => (<Page {...props} component={AdminToolsPage} title='Admin Tools' />)} />
                        <Route exact path='/admintools/migrateClientImages' render={props => (<Page {...props} component={MigrateClientImages} title='Migrate Client Images' />)} />
                    </App>
                </Auth>
            </div>
        </Switch>
    </div>

