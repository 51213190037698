
import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';
import * as auth from '../../../utils/auth';
import * as VoucherState from '../../../store/pages/vouchers/types';
import * as VoucherActions from '../../../store/pages/vouchers/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import VoucherProductForm from './voucherProductForm';
import Loading from '../../global/loading';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { stringComparer } from '../../../utils/comparers';
import { ProductPricingMode } from '../../../store/pages/products/types';
import { findApplicablePrice } from '../diary/helpers';


interface LocalProps { }

interface LocalState {
    voucherProducts: VoucherState.VoucherProduct[];
    isLoading: boolean;
    loadError: api.ApiError | null;
}

interface Actions {
    loadVoucherProducts: () => void;
    editVoucherProduct: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

type EditVouchersPageProps = LocalState & Actions & RoutedLocalProps;

interface EditVouchersPageState {
    showArchived: boolean;
}

class EditVouchersPage extends React.Component<EditVouchersPageProps, EditVouchersPageState> {

    constructor(props: EditVouchersPageProps) {
        super(props);

        this.state = { showArchived: false }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private editVoucherProduct = (voucherProduct: VoucherState.VoucherProduct) => {
        this.showOverlay(voucherProduct, false);
    }

    private addVoucherProduct = () => {
        this.showOverlay(null, true);
    }

    private showOverlay = (voucherProduct: VoucherState.VoucherProduct | null, addingNew: boolean) => {
        this.props.editVoucherProduct();
        this.props.showModal(<VoucherProductForm isNew={addingNew} voucherProduct={voucherProduct} />, 'VoucherProductForm');
    }

    render() {

        const { t } = this.context;
        const { isLoading, loadError } = this.props;
        const { showArchived } = this.state;
        const body = isLoading ? <Loading /> : this.renderVoucherProductsTable(showArchived);

        if (!auth.isClientAdmin()) {
            return <div className='alert alert-danger'>{t('ApiError:unauthorized')}</div>
        }

        return <section className='vouchersPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='vouchers_title'>{t('VouchersPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <ct.Checkbox id='showArchived' labelKey='Global:showArchived' value={ct.asFormValue('showArchived',showArchived)} callback={val => this.setState({ showArchived: val })} style={({ display: 'inline-block' })} />
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addVoucherProduct)}>{t('VouchersPage:addVoucherProduct')}</button>
                </div>
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }
    
    private renderVoucherProductsTable(showArchived: boolean) {
        const { t } = this.context;

        const voucherProducts = this.props.voucherProducts
            .filter(v => showArchived || !v.archived)
            .sort((v1, v2) => stringComparer(v1.name, v2.name))
            .map(v => {
                const price = findApplicablePrice({ id: v.id, name: v.name, pricingMode: ProductPricingMode.PerUnit, pricing: v.pricing}, new Date(), null)
                const currentPrice = price ? price.unitPrice : 0;
                return <tr key={v.id}>
                    <td><Link to={`/vouchers/${v.id}/view`} className='pull-left'>{v.name}</Link></td>
                    {showArchived ? <td>{v.archived ? <span className='label label-danger'>{t('Global:archived')}</span> : null}</td> : null}
                    <td>{currentPrice.toFixed(2)}</td>
                    <td>{v.taxRateName}</td>
                </tr>
            });

        return <table className='table table-condensed'>
            <thead>
                <tr>
                    <th>{this.context.t('VouchersPage:nameHeading')}</th>
                    {showArchived ? <th>{t('Global:archived')}</th> : null}
                    <th>{this.context.t('Global:price')}</th>
                    <th>{this.context.t('VouchersPage:taxRateHeading')}</th>
                </tr>
            </thead>
            <tbody>
                {voucherProducts}
            </tbody>
        </table>;
    }
};

const matStateToProps = (state: ApplicationState) => ({
    voucherProducts: state.vouchers.voucherProducts,
    isLoading: state.vouchers.isLoading,
    loadError: state.vouchers.loadError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadVoucherProducts: bindActionCreators(VoucherActions.actionCreators.loadVoucherProducts, dispatch),
    editVoucherProduct: bindActionCreators(VoucherActions.actionCreators.editVoucherProduct, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(matStateToProps, mapDispatchToProps)(EditVouchersPage);

