
import * as React from 'react';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';

import { ActivityFormatAvailability } from '../../../../store/pages/activityFormats/types';
import { formHandler, clickHandler } from '../../../../utils/util';
import { Time, Days } from '../../../../store/global/types';
import { DateFormat, TimeFormat } from '../../../../store/pages/venues/types';

interface OnLineAvailabilityFormProps {
    availability: ActivityFormatAvailability
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    close: () => void;
    save: (availability: ActivityFormatAvailability) => void;
}

interface OnLineAvailabilityFormState {
    days: Days;
    fromTime: ct.FormValue<Time>;
    toTime: ct.FormValue<Time>;
    minParticipants: ct.FormValue<number>;
    maxParticipants: ct.FormValue<number>;
    minDaysBefore: ct.FormValue<number>;
    maxDaysBefore: ct.FormValue<number>;
    startDate: ct.FormValue<moment.Moment | null>;
    endDate: ct.FormValue<moment.Moment | null>;
}

class OnLineAvailabilityForm extends React.Component<OnLineAvailabilityFormProps, OnLineAvailabilityFormState> {

    constructor(props: OnLineAvailabilityFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    buildStateFromProps = (props: OnLineAvailabilityFormProps) => {
        const { availability } = props;

        const startDate = availability.startDate ? moment(availability.startDate) : null;

        return {
            days: availability.days,
            fromTime: this.validateFromTime(availability.fromTime),
            toTime: this.validateToTime(availability.fromTime, availability.toTime),
            minParticipants: this.validateMinParticipants(availability.minParticipants),
            maxParticipants: this.validateMaxParticipants(availability.maxParticipants),
            minDaysBefore: this.validateMinDaysBefore(availability.minDaysBefore),
            maxDaysBefore: this.validateMaxDaysBefore(availability.maxDaysBefore),
            startDate: ct.asFormValue('startDate', startDate),
            endDate: this.validateEndDate(startDate, availability.endDate ? moment(availability.endDate) : null),
        };
    }

    save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { days, fromTime, toTime, minParticipants, maxParticipants, minDaysBefore, maxDaysBefore, startDate, endDate } = this.state;
            this.props.save({
                ...this.props.availability,
                days: days,
                fromTime: fromTime.value,
                toTime: toTime.value,
                minParticipants: minParticipants.value,
                maxParticipants: maxParticipants.value,
                minDaysBefore: minDaysBefore.value,
                maxDaysBefore: maxDaysBefore.value,
                startDate: startDate.value ? startDate.value.local().toDate() : null,
                endDate: endDate.value ? endDate.value.local().toDate() : null,
            })
        }
    }

    setDay = (day: Days, value: boolean) => {
        this.setState(prev => {
            let currentDays = prev.days;

            if (value) currentDays |= day;
            else currentDays &= ~day

            return { days: currentDays }
        })
    }

    validateFromTime = (val: Time) => v.validate(val, 'fromTime', [v.required], []);
    validateToTime = (fromTime: Time, toTime: Time) => (toTime && fromTime && toTime.val() <= fromTime.val())
        ? { controlId: 'toTime', value: toTime, isValid: false, errorMessageKey: 'OnlineAvailabilityForm:toTimeBeforeFromTime', hasValidation: true }
        : v.validate(toTime, 'toTime', [v.required], []);
    validateMinParticipants = (val: number) => v.validate(val, 'minParticipants', [v.numeric, v.required], []);
    validateMaxParticipants = (val: number) => v.validate(val, 'maxParticipants', [v.numeric, v.required], []);
    validateMinDaysBefore = (val: number) => v.validate(val, 'minDaysBefore', [v.numeric], []);
    validateMaxDaysBefore = (val: number) => v.validate(val, 'maxDaysBefore', [v.numeric, v.required], []);
    validateEndDate = (startDate: moment.Moment | null, endDate: moment.Moment | null) =>  (endDate && startDate && endDate <= startDate)
        ? { controlId: 'endDate', value: endDate, isValid: false, errorMessageKey: 'OnlineAvailabilityForm:endDateBeforeStartDate', hasValidation: true }
        : { controlId: 'endDate', value: endDate, isValid: true, hasValidation: true };

    onFromTimeChanged = (fromTime: Time) => this.setState(s => ({ fromTime: ct.asFormValue('fromTime', fromTime), toTime: this.validateToTime(fromTime, s.toTime.value) }))
    onToTimeChanged = (toTime: Time) => this.setState(s => ({ fromTime: s.fromTime, toTime: this.validateToTime(s.fromTime.value, toTime) }))

    onStartDateChanged = (startDate: moment.Moment) => this.setState(s => ({ startDate: ct.asFormValue('startDate', startDate), endDate: this.validateEndDate(startDate, s.endDate.value) }))
    onEndDateChanged = (endDate: moment.Moment) => this.setState(s => ({ startDate: s.startDate, endDate: this.validateEndDate(s.startDate.value, endDate) }))

    render() {
        const { t } = this.context;
        const { timeFormat, dateFormat, close } = this.props;
        const { days, fromTime, toTime, minParticipants, maxParticipants, minDaysBefore, maxDaysBefore, startDate, endDate } = this.state;
        return <div>
            <h2>On-line availability form</h2>

            <form className='data-form' onSubmit={e => formHandler(e)} autoComplete='off'>
                <div className='row'>
                    <div className='col-xs-12'>
                        <table className='table table-super-condensed table-borderless' style={({ width: 'auto' })}>
                            <thead>
                                <tr>
                                    <td>{t('Global:Monday2ch')}</td>
                                    <td>{t('Global:Tuesday2ch')}</td>
                                    <td>{t('Global:Wednesday2ch')}</td>
                                    <td>{t('Global:Thursday2ch')}</td>
                                    <td>{t('Global:Friday2ch')}</td>
                                    <td>{t('Global:Saturday2ch')}</td>
                                    <td>{t('Global:Sunday2ch')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input type='checkbox' checked={(days & Days.Monday) === Days.Monday} onChange={x => this.setDay(Days.Monday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(days & Days.Tuesday) === Days.Tuesday} onChange={x => this.setDay(Days.Tuesday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(days & Days.Wednesday) === Days.Wednesday} onChange={x => this.setDay(Days.Wednesday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(days & Days.Thursday) === Days.Thursday} onChange={x => this.setDay(Days.Thursday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(days & Days.Friday) === Days.Friday} onChange={x => this.setDay(Days.Friday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(days & Days.Saturday) === Days.Saturday} onChange={x => this.setDay(Days.Saturday, x.currentTarget.checked)} /></td>
                                    <td><input type='checkbox' checked={(days & Days.Sunday) === Days.Sunday} onChange={x => this.setDay(Days.Sunday, x.currentTarget.checked)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.Time id='fromTime' labelKey='OnlineAvailabilityForm:fromTime' value={fromTime} timeFormat={timeFormat} callback={val => this.onFromTimeChanged(val ? val : Time.zero())} />
                    </div>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.Time id='toTime' labelKey='OnlineAvailabilityForm:toTime' value={toTime} timeFormat={timeFormat} callback={val => this.onToTimeChanged(val ? val : Time.zero())} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.NumberBox id='minParticipants' labelKey='OnlineAvailabilityForm:minParticipants' placeholderKey='OnlineAvailabilityForm:minParticipants' value={minParticipants} callback={val => this.setState({ minParticipants: this.validateMinParticipants(val || 0) })} min='0' />
                    </div>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.NumberBox id='maxParticipants' labelKey='OnlineAvailabilityForm:maxParticipants' placeholderKey='OnlineAvailabilityForm:maxParticipants' value={maxParticipants} callback={val => this.setState({ maxParticipants: this.validateMaxParticipants(val || 0) })} min='0' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.NumberBox id='maxDaysBefore' labelKey='OnlineAvailabilityForm:maxDaysBefore' placeholderKey='OnlineAvailabilityForm:maxDaysBefore' value={maxDaysBefore} callback={val => this.setState({ maxDaysBefore: this.validateMaxDaysBefore(val || 0) })} min='0' />
                    </div>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.NumberBox id='minDaysBefore' labelKey='OnlineAvailabilityForm:minDaysBefore' placeholderKey='OnlineAvailabilityForm:minDaysBefore' value={minDaysBefore} callback={val => this.setState({ minDaysBefore: this.validateMinDaysBefore(val || 0) })} min='0' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.DatePicker id='startDate' labelKey='OnlineAvailabilityForm:startDate' hintKey='OnlineAvailabilityForm:leaveStartDateBlank' value={startDate} callback={this.onStartDateChanged} dateFormat={dateFormat} />
                    </div>
                    <div className='col-xs-6 col-md-3 col-lg-2'>
                        <ct.DatePicker id='endDate' labelKey='OnlineAvailabilityForm:endDate' hintKey='OnlineAvailabilityForm:leaveEndDateBlank' value={endDate} callback={this.onEndDateChanged} dateFormat={dateFormat} />
                    </div>
                </div>

                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>
    }
}

export default OnLineAvailabilityForm;