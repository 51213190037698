
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../../store';
import { ValidationError } from '../../../../store/global/types';
import { PaymentMethod, PaymentMethodType } from '../../../../store/pages/paymentMethods/types';
import * as ct from '../../../global/controls';
import * as v from '../../../global/validation';
import * as PaymentMethodActions from '../../../../store/pages/paymentMethods/actions';
import * as ModalActions from '../../../../store/global/modal/actions';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../../utils/util';
import * as api from '../../../../store/apiClient';
import ApiError from '../../../global/apiError';
import { PaymentGateway, PaymentGatewayType } from '../../../../store/pages/paymentGateways/types';
import PaymentMethodGatewaySettings from './paymentMethodGatewaySettings';
import { stringComparer } from '../../../../utils/comparers';

export interface Setting {
    key: string;
    secure: boolean;
    hasValue: boolean;
    optional: boolean;
    value: ct.FormValue<string | null>;
}

interface LocalProps {
    venueId: string;
    isNew: boolean;
    paymentMethod: PaymentMethod | null;
    pageRoot: string;
}

interface MappedState {
    paymentGateways: PaymentGateway[];
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    lastSavedIntegrationKey: string | null;
    lastSavedId: string | null;
}

interface MappedActions {
    loadPaymentMethods: () => void;
    savePaymentMethod: (isNew: boolean, paymentMethodId: string | null, venueId: string, paymentMethod: PaymentMethod) => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

type PaymentMethodFormProps = LocalProps & MappedState & MappedActions;

interface PaymentMethodFormState {
    isNew: boolean;
    paymentMethodIntegrationKey: string | null;
    lastSavedId: string | null;
    name: ct.FormValue<string>;
    pointOfSaleText: ct.FormValue<string>;
    paymentMethodType: ct.FormValue<number>;
    paymentGatewayType: ct.FormValue<number|null>;
    openCashDrawer: ct.FormValue<boolean>;
    eventPaymentsOnly: ct.FormValue<boolean>;
    archived: ct.FormValue<boolean>;
    canRefund: ct.FormValue<boolean>;
    limitRefundToPaidAmount: ct.FormValue<boolean>;
    allowCustomerWebPayment: ct.FormValue<boolean>;
    webPaymentButtonText: ct.FormValue<string>;
    publicPaymentName: ct.FormValue<string>;
    extraFields: PaymentInformationValues[];
    settings: Setting[];
    errorMessage: string | null;
}

interface PaymentInformationValues {
    id: string;
    isNew: boolean;
    name: ct.FormValue<string>;
    required: ct.FormValue<boolean>;
}

class PaymentMethodForm extends React.Component<PaymentMethodFormProps, PaymentMethodFormState> {

    constructor(props: PaymentMethodFormProps) {
        super(props);

        this.state = this.buildStateFromProps(props, props.isNew);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: PaymentMethodFormProps, isNew: boolean): PaymentMethodFormState {

        const { paymentMethod } = props;

        const blankExtraField = this.createBlankExtraField();
        const allowCustomerWebPayment = (isNew || !paymentMethod) ? false : paymentMethod.allowCustomerWebPayment;

        return {
            isNew: isNew,
            lastSavedId: null,
            paymentMethodIntegrationKey: (isNew || !paymentMethod) ? null : paymentMethod.integrationKey,
            name: this.validateName((isNew || !paymentMethod) ? '' : paymentMethod.name),
            pointOfSaleText: this.validatePointOfSaleText((isNew || !paymentMethod) ? '' : paymentMethod.pointOfSaleText),
            paymentMethodType: this.validatePaymentMethodType((isNew || !paymentMethod) ? 0 : paymentMethod.type),
            paymentGatewayType: this.validatePaymentGatewayType((isNew || !paymentMethod) ? null : paymentMethod.paymentGatewayId),
            openCashDrawer: this.validateOpenCashDrawer((isNew || !paymentMethod) ? false : paymentMethod.openCashDrawer),
            eventPaymentsOnly: this.validateEventPaymentsOnly((isNew || !paymentMethod) ? false : paymentMethod.eventPaymentsOnly),
            archived: this.validateArchived((isNew || !paymentMethod) ? false : paymentMethod.archived),
            canRefund: this.validateCanRefund((isNew || !paymentMethod) ? false : paymentMethod.canRefund),
            limitRefundToPaidAmount: this.validateLimitRefundToPaidAmount((isNew || !paymentMethod) ? false : paymentMethod.limitRefundToPaidAmount),
            allowCustomerWebPayment: this.validateAllowCustomerWebPayment(allowCustomerWebPayment),
            webPaymentButtonText: this.validateWebPaymentButtonText((isNew || !paymentMethod) ? '' : paymentMethod.webPaymentButtonText, allowCustomerWebPayment),
            publicPaymentName: this.validatePublicPaymentName((isNew || !paymentMethod) ? '' : paymentMethod.publicPaymentName),
            extraFields: (isNew || !paymentMethod) ? [blankExtraField] : paymentMethod.extraFields.map(f => ({ isNew: false, id: f.id, name: this.validateExtraFieldName(f.id, f.name), required: this.validateExtraFieldRequired(f.id, f.required) })).concat([blankExtraField]),
            settings: (isNew || !paymentMethod) ? [] : paymentMethod.settings.map(s => ({ key: s.key, secure: s.secure, hasValue: s.hasValue, value: this.validateSetting(s.key, s.secure ? '' : s.unsecuredValue, !s.optional && !s.hasValue), optional: s.optional })),
            errorMessage: null
        };
    }

    componentDidUpdate(prevProps: PaymentMethodFormProps) {
        // Only update state is tax rate has changed
        const { paymentMethod: prevPaymentMethod, saveComplete: prevSaveComplete } = prevProps;
        const { paymentGatewayType, isNew } = this.state;
        const { paymentMethod, saveComplete, lastSavedIntegrationKey, lastSavedId } = this.props;

        if ((!prevPaymentMethod && paymentMethod) || (prevPaymentMethod && !paymentMethod) || (prevPaymentMethod && paymentMethod && prevPaymentMethod.id !== paymentMethod.id)) {
            this.setState(this.buildStateFromProps(this.props, !paymentMethod || isNullOrEmpty(paymentMethod.id)));
        }

        // If this is a new stripe poayment method, don't close the form as we need to connect to stripe
        if (saveComplete && !prevSaveComplete) {
            if (isNew && paymentGatewayType.value === PaymentGatewayType.Stripe) {
                this.setState({ isNew: false, paymentMethodIntegrationKey: lastSavedIntegrationKey, lastSavedId: lastSavedId });
            } else {
                setTimeout(() => { this.close(); }, 750);
            }
        }
    }

    createBlankExtraField = (): PaymentInformationValues => {
        const blankExtraFieldId = generateTempId();
        return { isNew: true, id: blankExtraFieldId, name: this.validateExtraFieldName(blankExtraFieldId, ''), required: this.validateExtraFieldRequired(blankExtraFieldId, false) };
    }

    savePaymentMethod = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    close = () => {
        this.props.closeModal();
    }

    save = () => {
        const { isNew, extraFields, paymentGatewayType, settings } = this.state;

        if (!v.isValid(this.state)) {
            this.setState({ errorMessage: 'Global:formNotValid' });
        } else if (extraFields.filter(f => !f.name.isValid || !f.required.isValid).length > 0) {
            this.setState({ errorMessage: 'Global:formNotValid' });
        } else if (paymentGatewayType.value && (!isNew || paymentGatewayType.value !== PaymentGatewayType.Stripe) && settings.filter(s => !s.value.isValid).length > 0) {
            this.setState({ errorMessage: 'Global:formNotValid' });
        } else {
            const { paymentMethod, venueId, savePaymentMethod, lastSavedId } = this.props;
            const paymentMethodId = lastSavedId || (isNew || !paymentMethod ? null : paymentMethod.id);
            const { name, pointOfSaleText, openCashDrawer, eventPaymentsOnly, paymentMethodType, paymentGatewayType, archived, canRefund,
                limitRefundToPaidAmount, allowCustomerWebPayment, extraFields, settings, webPaymentButtonText, publicPaymentName } = this.state;

            this.setState({ errorMessage: null });

            const updatedSettings = paymentGatewayType.value
                ? settings
                    .filter(s => s.value.isValid && s.value.value)
                    .map(s => ({ key: s.key, secure: s.secure, hasValue: false, unsecuredValue: s.value.value, optional: s.optional }))
                    .concat(this.props.paymentMethod ? this.props.paymentMethod.settings.filter(es => !settings.find(x => x.key === es.key)).map(es => ({ key: es.key, secure: es.secure, hasValue: false, unsecuredValue: null, optional: es.optional, delete: true })) : [])
                : [];

            savePaymentMethod(isNew,
                paymentMethodId,
                venueId,
                {
                    id: lastSavedId || paymentMethodId || '',
                    venueId: venueId,
                    name: name.value,
                    pointOfSaleText: pointOfSaleText.value,
                    openCashDrawer: openCashDrawer.value,
                    eventPaymentsOnly: eventPaymentsOnly.value,
                    type: paymentMethodType.value,
                    paymentGatewayId: paymentGatewayType.value,
                    paymentGatewayType: PaymentGatewayType.None,
                    settings: updatedSettings,
                    extraFields: extraFields.filter(f => !isNullOrEmpty(f.name.value)).map(f => ({ id: f.isNew ? '' : f.id, name: f.name.value, required: f.required.value })),
                    archived: archived.value,
                    canRefund: this.allowRefunds(paymentMethodType.value, paymentGatewayType.value) && canRefund.value,
                    limitRefundToPaidAmount: limitRefundToPaidAmount.value,
                    allowCustomerWebPayment: allowCustomerWebPayment.value,
                    webPaymentButtonText: webPaymentButtonText.value,
                    publicPaymentName: publicPaymentName.value,
                    paymentGatewayMinimumPaymentAmount: null,
                    integrationKey: null
                }
            );
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validatePointOfSaleText = (val: string) => v.validate(val, 'pointOfSaleText', [v.required], this.props.validationErrors);
    validatePaymentMethodType = (val: number) => v.validate(val, 'type', [], this.props.validationErrors);
    validateOpenCashDrawer = (val: boolean) => v.validate(val, 'openCashDrawer', [], this.props.validationErrors);
    validateEventPaymentsOnly = (val: boolean) => v.validate(val, 'eventPaymentsOnly', [], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateCanRefund = (val: boolean) => v.validate(val, 'canRefund', [], this.props.validationErrors);
    validateLimitRefundToPaidAmount = (val: boolean) => v.validate(val, 'limitRefundToPaidAmount', [], this.props.validationErrors);
    validateAllowCustomerWebPayment = (val: boolean) => v.validate(val, 'allowCustomerWebPayment', [], this.props.validationErrors);
    validateWebPaymentButtonText = (val: string, webPaymentEnabled: boolean) => v.validate(val, 'webPaymentButtonText', webPaymentEnabled ? [v.required] : [], this.props.validationErrors);
    validatePublicPaymentName = (val: string) => v.validate(val, 'publicPaymentName', [v.required], this.props.validationErrors);

    validateExtraFieldName = (id: string, val: string) => v.validate(val, `${id}_name`, [], this.props.validationErrors);
    validateExtraFieldRequired = (id: string, val: boolean) => v.validate(val, `${id}_required`, [], this.props.validationErrors);

    validateSetting = (key: string, val: string | null, required: boolean) => v.validate(val, `_setting_${key}`, this.getSettingValidations(required), this.props.validationErrors);
    getSettingValidations = (required: boolean) => required ? [v.required] : [];

    validatePaymentGatewayType = (val: number | null) => v.validate(val, 'gateway', [], this.props.validationErrors);

    paymentMethodTypeChanged = (type: string) => {
        const { paymentGateways } = this.props;
        const intVal = parseInt(type);

        this.setState({ paymentMethodType: this.validatePaymentMethodType(intVal) });

        if (intVal === PaymentMethodType.Gateway) {
            const defaultGatewayId = (paymentGateways.filter(g => g.type === PaymentGatewayType.Stripe)[0] || paymentGateways[0]).id
            this.paymentGatewayChanged(defaultGatewayId);
        } else {
            this.setState(prev => ({ paymentGatewayType: this.validatePaymentGatewayType(null)}));
        }
    }

    selectedPaymentGatewayChanged = (type: string) => this.paymentGatewayChanged(parseInt(type));

    paymentGatewayChanged = (type: number) => {

        const val = this.validatePaymentGatewayType(type);

        let settings: Setting[] = [];
        if (val.isValid && val.value) {
            const gateway = this.props.paymentGateways.find(g => g.id === val.value)
            const { settings: currentSettings } = this.state;
            if (gateway) {
                settings = settings.concat(Object.keys(gateway.settings).map(key => {
                    const existing = currentSettings.find(s => s.key === key);
                    return ({ key: key, secure: gateway.settings[key].secret, hasValue: false, value: existing ? existing.value : this.validateSetting(key, '', !gateway.settings[key].optional), optional: gateway.settings[key].optional });
                }));
            }
        }

        this.setState({ paymentGatewayType: val, settings: settings });
    }

    extraFieldNameChanged = (id: string, val: string) => this.setState(prev => {
        const items = prev.extraFields.map(f => f.id === id ? { ...f, name: this.validateExtraFieldName(id, val) } : f);
        const addNew = !items.find(i => i.id !== id && isNullOrEmpty(i.name.value));
        return { extraFields: addNew ? items.concat([this.createBlankExtraField()]) : items };
    });

    extraFieldRequiredChanged = (id: string, val: boolean) => this.setState(prev => ({ extraFields: prev.extraFields.map(f => f.id === id ? { ...f, required: this.validateExtraFieldRequired(id, val) } : f) }));

    settingChanged = (key: string, val: string) => this.setState(prev => {
        const settings = prev.settings.map(s => s.key === key ? { ...s, value: this.validateSetting(key, val, !s.optional && !s.hasValue) } : s);
        return { settings: settings };
    });

    onPaymentMethodChanged = () => {
        this.props.loadPaymentMethods();
        this.close();
    }

    allowRefunds = (paymentMethodType: PaymentMethodType, paymentGatewayType: PaymentGatewayType | null) => {
        return paymentMethodType !== PaymentMethodType.Gateway || (paymentGatewayType !== null && paymentGatewayType !== PaymentGatewayType.NatWestTyl && paymentGatewayType !== PaymentGatewayType.LibertyPay && paymentGatewayType !== PaymentGatewayType.WorldpayBg350);
    }

    render() {

        const { t } = this.context;
        const { paymentGateways, saveError, saveComplete } = this.props;
        const { isNew, name, pointOfSaleText, openCashDrawer, eventPaymentsOnly, paymentMethodType, paymentGatewayType,
            archived, canRefund, limitRefundToPaidAmount, allowCustomerWebPayment, extraFields, settings, errorMessage,
            paymentMethodIntegrationKey, publicPaymentName, webPaymentButtonText } = this.state;
        const paymentMethodTypeOptions = Object.keys(PaymentMethodType).filter(k => typeof PaymentMethodType[k as any] === 'number').map(k => ({ key: PaymentMethodType[k as any].toString(), name: t(`PaymentMethodType:${k}`) })).sort((o1, o2) => stringComparer(o1.name, o2.name));
        let message: any;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (!isNullOrEmpty(errorMessage)) {
            message = (<div className='bg-danger'>{t(errorMessage)}</div>);
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const allowRefunds = this.allowRefunds(paymentMethodType.value, paymentGatewayType.value);

        return <div className='payment-method-page'>
            <h1 className='paymentMethod_title'>{isNew ? t('PaymentMethodForm:addPaymentMethod') : t('PaymentMethodForm:editPaymentMethod')}</h1>

            <form className='data-form' onSubmit={this.savePaymentMethod} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.TextBox id='pointOfSaleText' labelKey='PaymentMethodForm:pointOfSaleText' placeholderKey='PaymentMethodForm:pointOfSaleText' value={pointOfSaleText} callback={val => this.setState({ pointOfSaleText: this.validatePointOfSaleText(val) })} />

                <ct.TextBox id='publicPaymentName' labelKey='PaymentMethodForm:publicPaymentName' placeholderKey='PaymentMethodForm:publicPaymentName' value={publicPaymentName} callback={val => this.setState({ publicPaymentName: this.validatePublicPaymentName(val) })} />

                <ct.Checkbox id='openCashDrawer' labelKey='PaymentMethodForm:openCashDrawer' value={openCashDrawer} callback={val => this.setState({ openCashDrawer: this.validateOpenCashDrawer(val) })} />

                <ct.Checkbox id='eventPaymentsOnly' labelKey='PaymentMethodForm:eventPaymentsOnly' value={eventPaymentsOnly} callback={val => this.setState({ eventPaymentsOnly: this.validateEventPaymentsOnly(val) })} />

                <ct.Select id='type' labelKey='PaymentMethodForm:paymentMethodType' value={({ ...paymentMethodType, value: paymentMethodType.value.toString() })} callback={this.paymentMethodTypeChanged} options={paymentMethodTypeOptions} />

                {this.renderGateway(paymentMethodIntegrationKey, paymentMethodType, paymentGatewayType, paymentGateways, settings)}

                <p className='mt-15' />

                {allowRefunds ? <ct.Checkbox id='canRefund' labelKey='PaymentMethodForm:canRefund' value={canRefund} callback={val => this.setState({ canRefund: this.validateCanRefund(val) })} disabled={paymentMethodType.value === PaymentMethodType.Voucher} /> : null}
                {allowRefunds ? <ct.Checkbox id='limitRefundToPaidAmount' labelKey='PaymentMethodForm:limitRefundToPaidAmount' value={limitRefundToPaidAmount} callback={val => this.setState({ limitRefundToPaidAmount: this.validateLimitRefundToPaidAmount(val) })} disabled={!canRefund || paymentMethodType.value === PaymentMethodType.Voucher} /> : null}

                <ct.Checkbox id='allowCustomerWebPayment' labelKey='PaymentMethodForm:allowCustomerWebPayment' value={allowCustomerWebPayment} callback={val => this.setState({ allowCustomerWebPayment: this.validateAllowCustomerWebPayment(val) })} />

                {allowCustomerWebPayment.value ? <ct.TextBox id='webPaymentButtonText' labelKey='PaymentMethodForm:webPaymentButtonText' placeholderKey='PaymentMethodForm:webPaymentButtonText' value={webPaymentButtonText} callback={val => this.setState({ webPaymentButtonText: this.validateWebPaymentButtonText(val, true) })} /> : null}

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {this.renderAdditionalInfo(extraFields)}

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }

    renderGateway = (paymentMethodIntegrationKey: string | null, paymentMethodType: ct.FormValue<number>, paymentGatewayType: ct.FormValue<number|null>, paymentGateways: PaymentGateway[], settings: any) => {
        if (!paymentMethodType || paymentMethodType.value !== PaymentMethodType.Gateway) {
            return null;
        }

        const gatewayOptions = paymentGateways.sort((g1, g2) =>
        {
            if (g1.type === PaymentGatewayType.Stripe && g2.type === PaymentGatewayType.Stripe) return 0;
            if (g1.type === PaymentGatewayType.Stripe) return -1;
            if (g2.type === PaymentGatewayType.Stripe) return 1;
            return stringComparer(g1.name, g2.name);
        }).map(g => ({ key: g.id.toString(), name: this.context.t(`PaymentGateway:${g.name}`) }));

        const currentSelection = paymentGatewayType.value || gatewayOptions[0].key;

        return (
            <>
                <ct.Select id='gateway' labelKey='PaymentMethodForm:paymentGatewayType' value={({ ...paymentGatewayType, value: currentSelection.toString() })} callback={this.selectedPaymentGatewayChanged} options={gatewayOptions} />

                {this.renderSettings(paymentMethodIntegrationKey, paymentGatewayType, settings)}

            </>
        );
    }

    renderSettings = (paymentMethodIntegrationKey: string | null, paymentGatewayType: ct.FormValue<number | null>, settings: Setting[]) => {
        if (!paymentGatewayType.isValid || !paymentGatewayType.value || settings.length === 0) {
            return null;
        }

        const gateway = this.props.paymentGateways.find(g => g.id === paymentGatewayType.value);
        return <PaymentMethodGatewaySettings
            paymentMethodIntegrationKey={paymentMethodIntegrationKey}
            gateway={gateway}
            settings={settings}
            settingChanged={this.settingChanged}
            pageRoot={this.props.pageRoot}
            closeModal={this.props.closeModal}
            showModal={this.props.showModal}
            onPaymentMethodChanged={this.onPaymentMethodChanged}
        />
    }
        
    renderAdditionalInfo = (extraFields: PaymentInformationValues[]) => {
        const { t } = this.context;

        return (
            <div>
                <label>{t('PaymentMethodForm:additionalInformation')}</label>
                <table>
                    <thead>
                        <tr>
                            <th>{t('Global:name')}</th>
                            <th>{t('Global:required')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {extraFields.map(x => (
                            <tr key={x.id}>
                                <td><input id={`${x.id}_name`} type='text' value={x.name.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.extraFieldNameChanged(x.id, e.currentTarget.value)} style={({ marginBottom: '5px' })} /></td>
                                <td><input id={`${x.id}_required`} type='checkbox' checked={x.required.value} onChange={e => this.extraFieldRequiredChanged(x.id, e.currentTarget.checked)} style={({marginLeft: '20px', marginBottom: '5px'})} /></td>
                            </tr>))}
                    </tbody>
                </table>
            </div>
        );
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.paymentMethods.saveComplete,
    saveError: state.paymentMethods.saveError,
    validationErrors: state.paymentMethods.validationErrors,
    lastSavedIntegrationKey: state.paymentMethods.lastSavedIntegrationKey,
    lastSavedId: state.paymentMethods.lastSavedId,
    paymentGateways: state.paymentGateways.paymentGateways
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadPaymentMethods: bindActionCreators(PaymentMethodActions.actionCreators.loadPaymentMethods, dispatch),
    savePaymentMethod: bindActionCreators(PaymentMethodActions.actionCreators.savePaymentMethod, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(PaymentMethodForm);
