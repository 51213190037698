import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../../store/apiClient';

import { BillPayment } from '../../../../store/pages/pointOfSale/types';
import { PaymentMethod } from '../../../../store/pages/paymentMethods/types';
import { PaymentGatewayType } from '../../../../store/pages/paymentGateways/types';
import ProcessPaymentsensePayment from './processPaymentsensePayment';
import ProcessStripePayment from './stripe/processStripePayment';
import ProcessLibertyPayPayment from './processLibertyPayPayment';
import ProcessWorldpayBg350Payment from './processWorldpayBg350Payment';
import ProcessNatWestTylPayment from './processNatWestTylPayment';

interface ProcessGatewayPaymentProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    amount: number;
    paymentMethod: PaymentMethod;
    onSaveError: (saveError: api.ApiError) => void;
    onComplete: (billId: string) => void;
    onCancelled: (billId: string) => void;
    logout: () => void;
}

interface ProcessGatewayPaymentState {
    alertMessage?: JSX.Element | null;
}

export default class ProcessGatewayPayment extends React.Component<ProcessGatewayPaymentProps, ProcessGatewayPaymentState> {

    constructor(props: ProcessGatewayPaymentProps) {
        super(props);

        this.state = {
            alertMessage: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }
    
    render() {
        const { t } = this.context;
        const { alertMessage } = this.state;
        const { venueId, billId, payment, amount, paymentMethod, onSaveError, onComplete, onCancelled, logout } = this.props;

        if (alertMessage) {
            return (
                <div className='pos_alert'>
                    {alertMessage}
                </div>);
        }

        switch (paymentMethod.paymentGatewayType) {
            case PaymentGatewayType.Paymentsense:
                return <ProcessPaymentsensePayment venueId={venueId} billId={billId} payment={payment} amount={amount} paymentMethod={paymentMethod} onSaveError={onSaveError} onComplete={onComplete} showAlert={c => this.setState({ alertMessage: c })} clearAlert={() => this.setState({alertMessage: null})} />
            case PaymentGatewayType.LibertyPay:
                return <ProcessLibertyPayPayment venueId={venueId} billId={billId} payment={payment} amount={amount} paymentMethod={paymentMethod} onSaveError={onSaveError} onComplete={onComplete} showAlert={c => this.setState({ alertMessage: c })} clearAlert={() => this.setState({ alertMessage: null })} />
            case PaymentGatewayType.Stripe:
                return <ProcessStripePayment venueId={venueId} billId={billId} payment={payment} amount={amount} paymentMethod={paymentMethod} onSaveError={onSaveError} onComplete={onComplete} onCancelled={onCancelled} showAlert={c => this.setState({ alertMessage: c })} clearAlert={() => this.setState({ alertMessage: null })} logout={logout} />
            case PaymentGatewayType.WorldpayBg350:
                return <ProcessWorldpayBg350Payment venueId={venueId} billId={billId} payment={payment} amount={amount} paymentMethod={paymentMethod} onSaveError={onSaveError} onComplete={onComplete} showAlert={c => this.setState({ alertMessage: c })} clearAlert={() => this.setState({ alertMessage: null })} />
            case PaymentGatewayType.NatWestTyl:
                return <ProcessNatWestTylPayment venueId={venueId} billId={billId} payment={payment} amount={amount} paymentMethod={paymentMethod} onSaveError={onSaveError} onComplete={onComplete} showAlert={c => this.setState({ alertMessage: c })} clearAlert={() => this.setState({ alertMessage: null })} />
        }

        return <div className='pos-process-gateway-payment-panel'>
            <h4 className='text-center'>{t('PointOfSale:processingPaymentHeader')}</h4>
            <div className='text-center'>{t('PointOfSale:processingPaymentText')}</div>
        </div>
    }
}